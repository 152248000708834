import { Container } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { retrieveAccessToken } from "../service/authService";

const AdminPage = () => {
    const [queryParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        let authCode = queryParams.get("code");
        if (authCode !== null) {
            sessionStorage.setItem("authCode", authCode);
            navigate("/admin");
        } else {
            authCode = sessionStorage.getItem("authCode");
            if (authCode !== null) {
                retrieveAccessToken().then(() => {
                    console.log(sessionStorage.getItem("AccessToken"));
                });
            }
        }
    });

    return (
        <Container>
            <h1 style={{color: "white"}}>AdminPage</h1>
        </Container>
    )
}

export default AdminPage;