import config from "../config.json";
import axios, {AxiosResponse} from "axios";

export const retrieveAccessToken = async () => {
    const authCode = sessionStorage.getItem("authCode");
    const response: AxiosResponse = await axios.get(
        config.url,
        {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "authCode": authCode
            }
        });
    console.log("Response: ", response);

    const accessToken = response.data.access_token;

    if (accessToken !== null) {
        sessionStorage.setItem("AccessToken", accessToken);
    }
}
